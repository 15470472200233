

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: "Product Sans";
  src: url("./assets/fonts/Product Sans Bold Italic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
	font-family: "Product Sans";
  src: url("./assets/fonts/Product Sans Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
	font-family: "Product Sans";
  src: url("./assets/fonts/Product Sans Italic.ttf") format("truetype");
  font-style: italic;
}

@font-face {
	font-family: "Product Sans";
  src: url("./assets/fonts/Product Sans Regular.ttf") format("truetype");
  font-weight: normal;
}

body *{
  font-family:  Arial, Helvetica, sans-serif;
  box-sizing: border-box;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media only screen and (min-width: 768px){
  body * {
    font-family: "Product Sans", Arial, Helvetica, sans-serif;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes firstBall{
  0% {
    left: -5px,
  }
  
  25% {
    left: 0px,
  }

  50% {
    left: 5px
  }
  75% {
    left: 0px,
  }

  100% {
    left: -5px,
  }
  
}
@-webkit-keyframes firstBall{
  0% {
    left: -5px,
  }
  
  25% {
    left: 0px,
  }

  50% {
    left: 5px
  }
  75% {
    left: 0px,
  }

  100% {
    left: -5px;
  }
}
@-moz-keyframes firstBall{
  0% {
    left: -5px,
  }
  
  25% {
    left: 0px,
  }

  50% {
    left: 5px
  }
  75% {
    left: 0px,
  }

  100% {
    left: -5px,
  }
}

@keyframes secondBall{
  0% {
    left: 10px,
  }
  25% {
    left: 15px,
  }

  50% {
    left: 20px
  }

  75% {
    left: 15px,
  }

  100% {
    left: 10px,
  }

  
}
@-webkit-keyframes secondBall{
  0% {
    left: 10px,
  }
  25% {
    left: 15px,
  }

  50% {
    left: 20px
  }

  75% {
    left: 15px,
  }
  
  100% {
    left: 10px,
  }
}
@-moz-keyframes secondBall{
  0% {
    left: 10px,
  }
  25% {
    left: 15px,
  }

  50% {
    left: 20px
  }

  75% {
    left: 15px,
  }
  
  100% {
    left: 10px,
  }
}

@keyframes thirdBall{
  0% {
    left: 25px,
  }
  25% {
    left: 30px,
  }
  
  50% {
    left: 35px
  }

  75% {
    left: 30px,
  }

  100% {
    left: 25px,
  }
}
@-webkit-keyframes thirdBall{
  0% {
    left: 25px,
  }
  25% {
    left: 30px,
  }
  
  50% {
    left: 35px
  }

  75% {
    left: 30px,
  }
  
  100% {
    left: 25px,
  }
}
@-moz-keyframes thirdBall{
  0% {
    left: 25px,
  }
  25% {
    left: 30px,
  }
  
  50% {
    left: 35px
  }

  75% {
    left: 30px,
  }
  
  100% {
    left: 25px,
  }
}

@keyframes pulse{
  0%{
    box-shadow: 0 0 0 0 rgba(232, 129, 56, 0.7), 0 0 0 0 rgba(232, 129, 56, 0.7);
  }
  40%{
    box-shadow: 0 0 0 18px rgba(232, 129, 56, 0), 0 0 0 0 rgba(232, 129, 56, 0.7);
  }
  80%{
    box-shadow: 0 0 0 18px rgba(232, 129, 56, 0), 0 0 0 10px rgba(232, 129, 56, 0);
  }
  100%{
    box-shadow: 0 0 0 0 rgba(232, 129, 56, 0), 0 0 0 10px rgba(232, 129, 56, 0);
  }
}
@-webkit-keyframes pulse{
  0%{
    box-shadow: 0 0 0 0 rgba(232, 129, 56, 0.7), 0 0 0 0 rgba(232, 129, 56, 0.7);
  }
  40%{
    box-shadow: 0 0 0 18px rgba(232, 129, 56, 0), 0 0 0 0 rgba(232, 129, 56, 0.7);
  }
  80%{
    box-shadow: 0 0 0 18px rgba(232, 129, 56, 0), 0 0 0 10px rgba(232, 129, 56, 0);
  }
  100%{
    box-shadow: 0 0 0 0 rgba(232, 129, 56, 0), 0 0 0 10px rgba(232, 129, 56, 0);
  }
}
@-moz-keyframes pulse{
  0%{
    box-shadow: 0 0 0 0 rgba(232, 129, 56, 0.7), 0 0 0 0 rgba(232, 129, 56, 0.7);
  }
  40%{
    box-shadow: 0 0 0 18px rgba(232, 129, 56, 0), 0 0 0 0 rgba(232, 129, 56, 0.7);
  }
  80%{
    box-shadow: 0 0 0 18px rgba(232, 129, 56, 0), 0 0 0 10px rgba(232, 129, 56, 0);
  }
  100%{
    box-shadow: 0 0 0 0 rgba(232, 129, 56, 0), 0 0 0 10px rgba(232, 129, 56, 0);
  }
}

